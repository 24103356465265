<template>
  <div class="main">
    <div class="top">
      <p class="top_left">
        <i class="el-icon-back" style="margin-right: 20px" @click="goBack"></i>
        <span>{{ title }}</span>
      </p>
      <p class="top_right"><span class="xinxin">*</span> 为必填项</p>
    </div>
    <el-divider></el-divider>
    <div class="content">
      <el-form
        :model="regitsterInfo"
        :rules="regitsterInfoRules"
        ref="regitsterInfoForm"
        label-width="180px"
      >
        <!-- 任务信息 -->
        <div class="register-info">
          <div class="title">
            <span class="blue"></span>
            <p style="font-size: 16px">任务信息</p>
          </div>
          <!-- {{regitsterInfo}} -->
          <div class="content-item">
            <el-form-item
              class="formitem"
              label="任务来源："
              prop="taskSourceCode"
            >
              <el-cascader
                v-if="echoAddress"
                class="select-width"
                ref="taskSourceRef"
                v-model="regitsterInfo.taskSourceCode"
                :props="{ ...props, checkStrictly: true }"
                @change="handleTaskSource"
                clearable
              ></el-cascader>
            </el-form-item>

            <el-form-item class="formitem" label="信息类别：" prop="infoType">
              <div class="ph">
                <el-radio-group
                  v-model="regitsterInfo.infoType"
                  class="select-width2ax"
                >
                  <el-radio
                    v-for="item in infoType"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
                <el-input
                  v-show="regitsterInfo.infoType == 3"
                  class="other-input"
                  v-model="regitsterInfo.infoTypeOther"
                  placeholder="请输入其他信息类别"
                ></el-input>
                <el-input
                  v-show="regitsterInfo.infoType == 1"
                  class="other-input"
                  v-model="regitsterInfo.letterNo"
                  placeholder="协查函编号"
                  :maxlength="32"
                ></el-input>
              </div>
            </el-form-item>
          </div>

          <div class="content-item">
            <el-form-item class="formitem" label="事件名称：" prop="eventName">
              <el-select
                :disabled="hxpd"
                class="select-width"
                filterable
                v-model="regitsterInfo.eventId"
                clearable
                placeholder="请输入事件名称"
              >
                <el-option
                  v-for="item in eventNameSelect"
                  :key="item.id"
                  :label="item.eventName"
                  :value="item.id"
                  @click.native="ldcs(item)"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="formitem" label="调查场所：">
              <el-select
                class="select-width"
                v-model="regitsterInfo.surveyAddress"
                clearable
                placeholder="请选择调查场所"
              >
                <el-option
                  v-for="item in eventcsSelect"
                  :key="item.id"
                  :label="item.placeName"
                  :value="item.placeName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="content-item">
            <el-form-item
              class="special"
              label="任务描述："
              prop="surveyResultDes"
            >
              <el-input
                type="textarea"
                v-model="regitsterInfo.taskRemark"
                placeholder="请输入内容"
                maxlength="200"
                show-word-limit
                class="select-width"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="附件:">
              <el-upload
                class="select-width"
                :action="upload"
                accept=".jpg, .png, .doc, .docx, .pdf, .xls, .xlsx"
                :before-upload="beforeUpload"
                :on-preview="handlePreview"
                :before-remove="beforeRemove"
                :on-remove="handleRemove"
                :on-success="handelSuccess"
                :on-error="handelError"
                :headers="myHeaders"
                :file-list="fileList"
              >
                <el-button
                  size="small"
                  icon="el-icon-upload"
                  v-if="preTaskStatus != 2"
                  >上传文件</el-button
                >
                <span slot="tip" class="el-upload-tip">
                  支持格式：.jpg .png .doc .docx .pdf .xls
                  .xlsx，单个文件不能超过20MB
                </span>
              </el-upload>
            </el-form-item>
          </div>
        </div>

        <!-- 流调信息 -->
        <div class="register-info">
          <div class="title">
            <span class="blue"></span>
            <p style="font-size: 16px">流调信息</p>
          </div>
          <!-- {{regitsterInfo}} -->
          <div class="content-item">
            <el-form-item
              class="formitem"
              label="调查人员："
              prop="surveyUserName"
            >
              <el-select
                v-model="regitsterInfo.surveyUserName"
                class="select-width"
              >
                <el-option
                  v-for="item in surveyUserNameSelect"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                  @click.native="handleSurveyUserName(item)"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="调查时间：" prop="surveyDate">
              <el-date-picker
                class="select-width"
                v-model="regitsterInfo.surveyDate"
                type="datetime"
                placeholder="选择时间"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
              ></el-date-picker>
            </el-form-item>
          </div>

          <div class="content-item">
            <div class="classify">
              <el-form-item
                class="formitem"
                label="人群分类："
                prop="crowdTypeId"
              >
                <el-select
                  class="select-width"
                  filterable
                  clearable
                  v-model="regitsterInfo.crowdTypeId"
                  placeholder="选择人群分类"
                >
                  <el-option
                    v-for="item in typepopSelect"
                    :key="item.id"
                    :label="item.ruleName"
                    :value="item.id"
                    @click.native="handelCrowdType(item)"
                  ></el-option>
                </el-select>

                <el-input
                  v-show="regitsterInfo.crowdType == 9"
                  class="other-input"
                  v-model="regitsterInfo.otherCrowdTypeName"
                  placeholder="请输入其他人群"
                  :maxlength="32"
                  width:500
                ></el-input>
              </el-form-item>
            </div>

            <el-form-item class="formitem" label="次要人群分类：">
              <el-select
                class="select-width"
                value-key="code"
                collapse-tags
                @change="selectchange($event)"
                v-model="selectArr"
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typepopSelect"
                  :key="item.id"
                  :label="item.ruleName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="content-item1">
            <el-form-item
              class="special1"
              label="流调结果："
              prop="surveyResult"
            >
              <div class="ph">
                <el-radio-group
                  v-model="regitsterInfo.surveyResult"
                  @change="ishome()"
                  class="select-width2ax"
                >
                  <el-radio
                    v-for="item in surveyResult"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div v-if="regitsterInfo.surveyResult == 3">
                关联已入住人员:
                <el-select
                  clearable
                  filterable
                  v-model="regitsterInfo.glrz"
                  class="select-width2"
                >
                  <el-option
                    v-for="item in typepopSelect11"
                    :key="item.id"
                    :label="item.zdm"
                    :value="item.regId"
                    @click.native="echo(item)"
                  ></el-option>
                </el-select>
              </div>
              <div v-if="regitsterInfo.surveyResult == 4">
                <el-input
                  class="select-width2"
                  v-model="regitsterInfo.otherResult"
                  placeholder="请输入其他类型"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item
              prop="surveyObjectBelongOrg"
              class="formitem"
              label="负责机构："
              v-if="
                regitsterInfo.surveyResult == 0 ||
                regitsterInfo.surveyResult == 2
              "
            >
              <el-select
                class="top_left_inputnationality select-width"
                filterable
                v-model="regitsterInfo.surveyObjectBelongOrg"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in homeOrgList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgName"
                  @click.native="handelSurveyObjectBelongOrg(item)"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="formitem"
              label="负责机构："
              v-if="regitsterInfo.surveyResult == 3"
              prop="surveyObjectBelongOrg"
            >
              <el-select
                class="top_left_inputnationality select-width"
                filterable
                v-model="regitsterInfo.surveyObjectBelongOrg"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in concentrateOrgList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgName"
                  @click.native="handelSurveyObjectBelongOrg(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="说明：" prop="surveyResultDes">
              <el-input
                type="textarea"
                v-model="regitsterInfo.surveyResultDes"
                placeholder="请输入内容"
                maxlength="200"
                show-word-limit
                class="select-width"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <!-- 调查对象基本信息 -->
        <div class="basic-info">
          <div class="title">
            <span class="blue"></span>
            <p style="font-size: 16px">调查对象基本信息</p>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="姓名：" prop="name">
              <el-input
                v-model.trim="regitsterInfo.name"
                placeholder="请输入姓名"
                class="select-width"
              />
            </el-form-item>
            <el-form-item class="formitem" label="性别：" prop="sex">
              <el-radio-group v-model="regitsterInfo.sex" class="select-width">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="证件类型：" prop="cardType">
              <el-select
                v-model="regitsterInfo.cardType"
                placeholder="证件类型"
                class="select-width"
              >
                <el-option
                  v-for="item in typeCertificate"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="证件号：" prop="cardNo">
              <el-input
                class="select-width"
                v-model.trim="regitsterInfo.cardNo"
                placeholder="请输入证件号"
                @blur="idCodeCount(regitsterInfo.cardNo)"
              />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="联系电话：" prop="linkPhone">
              <el-input
                class="select-width"
                maxlength="11"
                v-model.trim="regitsterInfo.linkPhone"
                placeholder="请输入联系电话"
              />
            </el-form-item>
            <el-form-item class="formitem" label="年龄：" prop="age">
              <el-input
                class="select-width"
                type="number"
                v-model="regitsterInfo.age"
                placeholder="请输入年龄"
              />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="国籍：" prop="country">
              <el-select
                filterable
                placeholder="请选择国籍"
                class="select-width"
                v-model="regitsterInfo.country"
              >
                <el-option
                  v-for="item in nationalityselet"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">
                    {{ item.value }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="民族：" prop="nation">
              <el-select
                class="top_left_inputnationality select-width"
                v-model="regitsterInfo.nation"
                placeholder="民族"
              >
                <el-option
                  v-for="item in nationSelect"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictLabel"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item
              class="formitem"
              label="现住地："
              prop="currentAddressCode"
            >
              <el-cascader
                v-if="echoAddress"
                ref="currentAddressRef"
                class="select-width"
                v-model="regitsterInfo.currentAddressCode"
                :props="arearProps"
                @change="handelCurrentAddress"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item
              class="formitem"
              label="详细地址："
              prop="currentAddressDetail"
            >
              <el-input
                type="text"
                class="select-width"
                v-model="regitsterInfo.currentAddressDetail"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item
              class="formitem"
              label="调查对象所属地区："
              v-if="
                regitsterInfo.surveyResult == 1 ||
                regitsterInfo.surveyResult == 3
              "
              prop="surveyObjectBelongAreaCode"
            >
              <el-cascader
                class="select-width"
                ref="surveyObjectBelongAreaRef"
                v-model="regitsterInfo.surveyObjectBelongAreaCode"
                v-if="echoAddress"
                :props="props"
                @change="handelSurveyObjectBelongArea"
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item
              class="formitem"
              label="调查对象所属地区："
              v-if="
                regitsterInfo.surveyResult != 1 &&
                regitsterInfo.surveyResult != 3
              "
            >
              <el-cascader
                class="select-width"
                ref="surveyObjectBelongAreaRef"
                v-model="regitsterInfo.surveyObjectBelongAreaCode"
                v-if="echoAddress"
                :props="props"
                @change="handelSurveyObjectBelongArea"
                clearable
              ></el-cascader>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <!-- 7为次密 -->
      <template v-if="regitsterInfo.crowdType == 7">
        <relate-close-contact
          @father="father"
          ref="closeContactRef"
          :addtype="this.type"
          :crowdType="regitsterInfo.crowdType"
        >
        </relate-close-contact>
      </template>
      <template :v-show="!!regitsterInfo.crowdType">
        <RelatePositiveCase
          @father102="father102"
          ref="positiveCaseRef"
          :addtype="this.type"
          :crowdType="regitsterInfo.crowdType"
        >
        </RelatePositiveCase>
      </template>
      <other-info ref="otherInfoRef" :formData="otherInfoData"></other-info>
    </div>
    <div class="btns">
      <el-button type="primary" @click="submitForm">提交</el-button>
      <el-button @click="resetForm">重置</el-button>
    </div>
    <!-- 提交对话框（警告框） -->
    <el-dialog
      title="警告提示"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="closeDialog"
    >
      <el-row class="warning" align="middle">
        <i
          class="el-icon-warning-outline"
          style="
            color: #ff9900;
            font-size: 26px;
            font-weight: bold;
            margin-right: 10px;
          "
        ></i>
        <span style="font-weight: bold; font-size: 16px"
          >当前保存用户疑似已入住隔离，请核实情况</span
        >
        <br />
        <br />

        <div
          class="notification"
          v-for="(item, index) in dialogMessage"
          :key="index"
        >
          <div class="NameNotification">
            {{ item.name }}
          </div>
          <div class="CardIdNotification">
            {{ item.cardNo }}
          </div>
          <div class="OrgPhoneNotification">
            {{ item.org }}({{ item.linkPhone ? item.linkPhone : "-" }})
          </div>
          <div class="TypeNotification">
            <span v-if="item.type == '隔离'"
              ><el-tag size="mini">隔离</el-tag></span
            >
            <span v-else><el-tag type="danger" size="mini">流调</el-tag></span>
          </div>
        </div>

        <!-- <div>
          <div v-for="item in dialogMessage" :key="item.linkPhone" class="notification">
            <span>{{item.name}}</span> &nbsp;
            <span>{{item.cardNo}}</span> &nbsp;
            <span>{{item.org}}</span> &nbsp;
            <span>({{item.linkPhone}})</span> &nbsp;
            <span v-if="item.type=='隔离'"><el-tag size="mini">隔离</el-tag></span>
            <span v-else><el-tag type="danger" size="mini">流调</el-tag></span>
          </div>
        </div> -->
      </el-row>
      <!-- <el-row :gutter="20">
        <el-col :span="3" :offset="2">{{ dialogMessage.name }}</el-col>
        <el-col :span="6">{{ dialogMessage.linkPhone }}</el-col>
        <el-col :span="6">{{ dialogMessage.org }}</el-col>
      </el-row> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">继续提交</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </span>
    </el-dialog>

    <!-- <dialog-info
      :dialogVisible="dialogVisible"
      title="警告提示"
      @closeDialog="closeDialog"
      @confirmDialog="closeDialog"
    >
      <el-row class="warning" type="flex" align="middle">
        <i
          class="el-icon-warning-outline"
          style="color:#ff9900;font-size:26px;font-weight:bold;margin-right:10px"
        ></i>
        <span style="font-weight:bold;font-size:16px">当前保存用户疑似已入住隔离，请核实情况</span>
      
        <div>

        </div>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="3" :offset="2">{{dialogMessage.name}}</el-col>
        <el-col :span="6">{{dialogMessage.linkPhone}}</el-col>
        <el-col :span="6">{{dialogMessage.org}}</el-col>
      </el-row>
    </dialog-info> -->
  </div>
</template>
<script>
import { _debounce } from "@/utils/utils.js";
import { http } from "@/api/index";
import {
  saveSurveyInfoApi,
  getSurveyInfoApi,
  getEventListApi,
  updateSurveyInfoApi,
  checkIsRegApi,
  getRegLiveUnSurveyUserList,
  getPlaceList,
  getRegById,
  relationList,
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { getToken } from "@/utils/auth";
import { getBusinessOrgList } from "@/api/SystemManagement/index"; //根据业务状态查询机构列表
import { getCrowdTypeList } from "@/api/RuleManagement/crowdAnalysis";
import {
  getMedicalOrgList,
  getUserListByPeopleTypeApi,
} from "@/api/SystemManagement/index";
import { configKey } from "../../../api/SystemManagement/isolationpointmanagement";
import constants from "@/common/constants";
import { SURVEY_RESULT, INFO_TYPE } from "@/common/constants";
import RelatePositiveCase from "./component/RelatePositiveCase.vue";
import RelateCloseContact from "./component/RelateCloseContact.vue";
import DialogInfo from "@/components/DialogInfo.vue";
import OtherInfo from "./component/OtherInfo.vue";
import { isIDCard, isPhone } from "@/utils/validate";
import { mapState } from "vuex";
import {
  getIdNumberInfo,
  downloadExportFile,
  importFile,
  importUrlFile,
} from "@/utils/utils";
import { Loading } from "element-ui";
import { array } from '@/utils/jszip';
export default {
  name: "AddEpidemiologicalSurvey",
  components: { RelatePositiveCase, OtherInfo, RelateCloseContact, DialogInfo },
  props: {
    // 接收从父组件传递过来的btn类型，addlidj ，add ，edit
    type: {
      type: String,
      default: "add",
    },
    id: {
      type: String,
      default: "",
    },
    rowdata: {
      type: [],
      default: "",
    },
  },
  data() {
    const phoneCheck = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };

    const cardNoCheck = (rule, value, callback) => {
      if (!isIDCard(value) && this.regitsterInfo.cardType == "1") {
        callback(new Error("请输入正确格式的身份证号"));
      } else {
        callback();
      }
    };
    return {
      checkType: "",
      isReg: [], // 查重人员数组
      selectArr: [],
      concentrateOrgList: [],
      homeOrgList: [],
      title: "流调登记表",
      echoAddress: false,
      cgldregId: "",
      myHeaders: {
        jtoken: getToken(),
        clientType: "ADMIN",
        userType: "USER_DOCTOR",
      }, //上传文件请求头
      upload: "/ohealth/api/v1/system/common/uploadFile", //文件上传地址
      dialogVisible: false, //对话框
      preTaskStatus: "", //任务状态
      hxpd: false,
      fileList: [], //回显时文件列表
      tempFileList: [], //备份文件列表
      typepopSelect11: [],
      eventcsSelect: [],
      dialogMessage: {}, //对话框
      surveyUserNameSelect: [], //调查人员项
      eventNameSelect: [], //事件名称项
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), //国籍选项
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型
      surveyObjectBelongOrgSelect: [], //所属社区
      infoType: INFO_TYPE,
      regitsterInfo: {
        infoType: "1", //信息类别：默认协查函
        taskRemark: "", //任务描述说明
        // majorpeopleclassify: '', //废弃主人群分类
        secondaryCrowdTypes: [], //次要人群分类多选
        // otherpeople: '', ////废弃的其他主人群分类
        taskSource: "",
        glrz: "",
        otherResult: "",
        taskSourceCode: [],
        surveyUserName: JSON.parse(localStorage.getItem("userdoctor")).nickName,
        surveyUserId: JSON.parse(localStorage.getItem("userdoctor")).id,
        eventId: "",
        eventName: "",
        surveyDate: new Date(),
        surveyAddress: "",
        surveyResult: "3",
        surveyResultDes: "",
        name: "",
        sex: "",
        cardType: "1",
        cardNo: "",
        linkPhone: "",
        age: "",
        country: "中国大陆",
        nation: "汉族",
        currentAddress: "",
        currentAddressBdTuid: "",
        currentAddressCode: [],
        currentAddressDetail: "",
        currentAddressGdTuid: "",
        crowdType: "",
        crowdTypeName: "",
        crowdTypeId: "",
        otherCrowdTypeName: "",
        surveyObjectBelongArea: "",
        surveyObjectBelongAreaCode: [],
        surveyObjectBelongOrg: "",
        surveyObjectBelongOrgCode: "",
        taskDesc: "",
      },
      otherInfoData: {
        medicalStaff: "",
        workUnit: "",
        clinicalSymptoms: "",
        dateOfFirstSymptom: "",
        firstSymptom: [],
        firstSymptomOther: "",
        vaccination: "",
        vaccinationType: [],
        vaccinationTypeOther: "",
        inoculationNum: "",
        finishVaccination: "",
        basicDiseases: "",
        basicDiseasesName: [],
        otherBasicDiseasesName: "",
      },
      typepopSelect: [],
      regitsterInfoRules: {
        taskSourceCode: [
          { required: true, message: "请选择任务来源", trigger: "change" },
        ],
        surveyUserName: [
          { required: true, message: "请选择调查人员", trigger: "change" },
        ],
        surveyDate: [
          { required: true, message: "请选择调查时间", trigger: "change" },
        ],
        crowdTypeId: [
          { required: true, message: "请选择人群分类", trigger: "change" },
        ],
        surveyAddress: [
          { required: true, message: "请输入调查场所", trigger: "blur" },
        ],
        surveyResult: [
          { required: true, message: "请选择流调结果", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        cardType: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        cardNo: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: cardNoCheck, trigger: "blur" },
        ],
        linkPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: phoneCheck, trigger: "blur" },
        ],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        currentAddressCode: [
          { required: true, message: "请输入现住地", trigger: "change" },
        ],
        currentAddressDetail: [
          { required: true, message: "请输入现住地详细地址", trigger: "blur" },
        ],
        // crowdTypeName: [
        //   { required: true, message: "请选择人群分类", trigger: "change" },
        // ],
        crowdType: [
          { required: true, message: "请选择人群分类", trigger: "change" },
        ],

        surveyObjectBelongAreaCode: [
          {
            required: true,
            message: "请选择调查对象所属地区",
            trigger: "change",
          },
        ],
        infoType: [
          { required: true, message: "请选择信息类别", trigger: "change" },
        ],
        surveyObjectBelongOrg: [
          { required: true, message: "请选择负责机构", trigger: "change" },
        ],
      },
      //3级
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node, '输出node，node00000')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));
              resolve(nodes);
            });
          }
        },
      },
      //4级
      arearProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node,resolve,'输出node，resolve1111111111')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 1000);
          } else {
            let formm = { label: node.data.value };
            // console.log(formm,'输出formm22222222222222')
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 4,
              }));
              resolve(nodes);
            });
          }
        },
      },
    };
  },
  computed: {
    ...mapState({
      nationSelect: (state) => state.user.nationSelect, // 民族
    }),
  },

  watch: {
    "regitsterInfo.currentAddressDetail"() {
      this.getBmap();
    },
    "regitsterInfo.currentAddress"() {
      this.getBmap();
    },
  },
  created() {
    this.isSystemCs(); //获取对应的地址jzy
    localStorage.setItem("bdtype", "add");
    //常量
    this.surveyResult = SURVEY_RESULT;
    this.echoAddress = this.type == "edit" ? false : true;

    if (this.type == "edit") {
      localStorage.setItem("bdtype", "edit");
      this.getSurveyInfo(this.id);
    }

    // 备注：这个是点击流调登记里面进来的详情(适用 流调员)
    if (this.type == "addlddj") {
      // console.log('dhhdhbhdh')
      this.regitsterInfo.currentAddressCode  = this.$store.state.defaultAddress.addressFourCode.split(',');//四级现住地code
      this.regitsterInfo.currentAddress = this.$store.state.defaultAddress.addressFourDetail;//四级现住地文字
      this.regitsterInfo.surveyObjectBelongAreaCode = this.$store.state.defaultAddress.addressThreeCode.split(',');//调查对象所属地区
      this.regitsterInfo.surveyObjectBelongArea = this.$store.state.defaultAddress.addressThreeDetail;//三级 调查所属地区
      // this.typepopSelect = this.$store.state.user.typepopSelect
      //查询居家隔离和排除的机构列表
      this.HomeOrgList(); 
      //查询转运任务业务机构列表
      this.TransOrgList(); 
      this.getCrowdTypeList();
      // console.log(this.rowdata,'this.this.rowdata')
      localStorage.setItem("addlddjType", "addlddj");
      this.checkType = "addlddj"; //流调进来显示任务描述字段不同保存到本地
      this.hxpd = true; //表示事件名称是否禁用
      let a = { ...this.regitsterInfo, ...this.rowdata }; //解构
      // console.log(a,'打印a，输出解构的this.regitsterInfo和this.rowdata')
      a.crowdType = a.crowdTypeCode; //crowdType和crowdTypeCode是同一种 （这里操作没有意义，因为本来就是相同的，先保留）
      // console.log(this.regitsterInfo,'打印this.regitsterInfo')
      // console.log(this.rowdata,'打印this.rowdataaaaaaaaaaaaaaaaaa')
      if (this.rowdata.cardType == null) {
        a.cardType = "1";
      }
      this.regitsterInfo = a; //流调人员回显
      this.regitsterInfo.taskRemark = this.regitsterInfo.taskDesc;
      this.regitsterInfo.id = "";
      this.regitsterInfo.taskSource = this.regitsterInfo.infoSource;
      this.idCodeCount(this.rowdata.cardNo);
      // console.log(a, "我在if条件上面一排打印的a");
      // console.log(a.files,'打印输出文件列表')
      // 文件回显
      if (a.files&&a.files.length > 0) {
        const nextFileList = a.files
          .filter((o) => o.url)
          .map((o) => ({ ...o, disableRemove: true }));
        this.fileList = [...nextFileList];
        this.tempFileList = [...nextFileList];
      }
    }

    this.getUserListByPeopleType();
    this.getOrgQueryList();
    this.getCrowdTypeList();

    if (this.type != "edit") {
      this.getRegLiveUnSurveyUserList();
    }

    this.HomeOrgList(); //查询居家隔离和排除的机构列表
    this.TransOrgList(); //查询转运任务业务机构列表

    if (this.type == "add") {
      // console.log(this.type,'addddd')
    }
  },

  mounted() {
    // console.log(this.type,'打印this.type')
    // 表示是从流调登记 新增里面点进来的
    if (this.type == "add") {
      this.regitsterInfo.currentAddressCode  = this.$store.state.defaultAddress.addressFourCode.split(',');//四级现住地code
      this.regitsterInfo.currentAddress = this.$store.state.defaultAddress.addressFourDetail;//四级现住地文字
      this.regitsterInfo.taskSourceCode = this.$store.state.defaultAddress.addressThreeCode.split(',');//三级任务来源地区
      this.regitsterInfo.taskSource = this.$store.state.defaultAddress.addressThreeDetail;//三级 任务来源所属地区
      this.regitsterInfo.surveyObjectBelongAreaCode = this.$store.state.defaultAddress.addressThreeCode.split(',');//调查对象所属地区
      this.regitsterInfo.surveyObjectBelongArea = this.$store.state.defaultAddress.addressThreeDetail;//三级 调查所属地区
    } 

    // if (this.type == "edit") {
    // }

    if (this.type == "addlddj") {
      this.regitsterInfo.taskSourceCode = this.regitsterInfo.infoSourceCode
        ? this.regitsterInfo.infoSourceCode.split(",")
        : [];
    }
    this.getEventList(); //获取事件名称
    // this.$forceUpdate();
  },
  methods: {
    // 流掉结果为居家隔离或者排除时候 （清空表单）
    ishome() {
      if (
        this.regitsterInfo.surveyResult == 0 ||
        this.regitsterInfo.surveyResult == 2 ||
        this.regitsterInfo.surveyResult == 3
      ) {
        this.regitsterInfo.surveyObjectBelongOrg = "";
      }
      this.regitsterInfo.otherResult = "";
      this.regitsterInfo.glrz = "";
    },

    // 根据业务状态查询业务机构列表(查询居家隔离和排除的机构列表)jzy
    async HomeOrgList() {
      let params = { homeIsolation: 1 };
      let { data } = await getBusinessOrgList({ params });
      if (data.code == 200) {
        this.homeOrgList = data.data;
      }
      // console.log(this.homeOrgList)
    },

    // 根据业务状态查询业务机构列表(集中隔离时，查询转运任务业务机构列表)jzy
    async TransOrgList() {
      let params = { transshipmentTask: 1 };
      let { data } = await getBusinessOrgList({ params });
      if (data.code == 200) {
        this.concentrateOrgList = data.data;
      }
      // console.log(this.homeOrgList)
    },

    // 获取对应的地址jzy
    async isSystemCs() {
      let configkey = "default_province_name";
      let result = await configKey(configkey);
      if (result.data.code == 200) {
        // console.log(result,'result打印result打印result打印result')
        // this.regitsterInfo.surveyObjectBelongArea = result.data.data;
      }
    },

    // 返回上一页面
    goBack() {
      this.$parent.isShowAdd = false;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    //登记详情
    async getSurveyInfo(id) {
      const params = { id: id };
      // console.log("row", this.rowdata);
      // this.regitsterInfo = this.rowdata; //流调人员回显
      const { data } = await getSurveyInfoApi({ params });
      // console.log(data, "打印datadata");
      if (data.code == "200") {
        // console.log("登记详情", data.data);
        // 调用调查场所
        let nzgsdwr = {
          id: data.data.survey.eventId,
        };
        this.ldcs(nzgsdwr);

        this.regitsterInfo = data.data.survey;
        // console.log(
        //   this.regitsterInfo,
        //   "打印获取到的this.regitsterInfo，请求个人数据的时候"
        // );
        // console.log(
        //   this.regitsterInfo,
        //   "回显的时候 regitsterInfo 获取到的个人信息"
        // );
        this.cgldregId = this.regitsterInfo.id;

        // this.regitsterInfo.regId = this.regitsterInfo.id;

        this.bjgetRegLiveUnSurveyUserList();
        if (data.data.files) {
          for (let i in data.data.files) {
            // data.data.files[i].name = data.data.files[i].fileName
            // data.data.files[i].url =  data.data.files[i].fileUrl
            if (data.data.files[i].fileUrl) {
              let a = {
                name: data.data.files[i].fileName,
                url: data.data.files[i].fileUrl,
              };
              this.fileList.push(a);
              this.tempFileList = this.fileList;
            }
          }
        }

        if (data.data.survey.secondaryCrowdTypes.length > 0) {
          for (let index in data.data.survey.secondaryCrowdTypes) {
            if (data.data.survey.secondaryCrowdTypes[index].crowdTypeId) {
              this.selectArr.push(
                data.data.survey.secondaryCrowdTypes[index].crowdTypeId
              );
            }
          }
        }

        //处理省市区回填

        this.regitsterInfo.taskSourceCode = this.regitsterInfo.taskSourceCode
          ? this.regitsterInfo.taskSourceCode.split(",")
          : [];

        this.regitsterInfo.currentAddressCode = this.regitsterInfo
          .currentAddressCode
          ? this.regitsterInfo.currentAddressCode.split(",")
          : [];

        this.regitsterInfo.surveyObjectBelongAreaCode = this.regitsterInfo
          .surveyObjectBelongAreaCode
          ? this.regitsterInfo.surveyObjectBelongAreaCode.split(",")
          : [];
        this.echoAddress = true;
        //渲染其他信息
        this.renderOtherInfo();
        //渲染阳性病例、密接者组件数据

        let surveyRelationList = data.data.surveyRelationList;
        // console.log(surveyRelationList, 'surveyRelationList')
        // for (let i in surveyRelationList) {
        //   if (surveyRelationList[i].probabilityRelation == true) {
        //     surveyRelationList[i].probabilityRelation = "123";
        //   }
        // }
        // console.log(surveyRelationList[0].probabilityRelation === '1', 'bool===>')
        // 为阳性的关联数组赋值
        let positiveCaseData = surveyRelationList
          .filter((o) => o.type === "0")
          .map((i) => {
            return {
              ...i,
              probabilityRelation:
                this.type == "edit" && i["probabilityRelation"] === "1",
            };
          });

        // console.log(positiveCaseData,'positiveCaseData打印为阳性的关联数组赋值')

        // 为一密的关联数组赋值
        let closeContactData = surveyRelationList
          .filter((o) => o.type === "1")
          .map((i) => {
            return {
              ...i,
              // probabilityRelation: (i.sort != 1 || this.type != "edit") || (i['probabilityRelation'] === '1'),
              probabilityRelation:
                this.type == "edit" && i["probabilityRelation"] === "1",
            };
          });

        // console.log(closeContactData,'closeContactData 一密 的关联数组赋值')

        // surveyRelationList = { ...surveyRelationList };
        // let aa = [];
        // for (let i in surveyRelationList) {
        //   // console.log(surveyRelationList[i]);
        //   aa.push(surveyRelationList[i]);
        // }

        // console.log(aa, 'aa')

        // surveyRelationList = aa;
        // if (
        //   data.data.surveyRelationList &&
        //   data.data.surveyRelationList.length == 0
        // )
        //   return;
        // let positiveCaseData = aa.filter((item) => {
        //   item.probabilityRelation =
        //     item.probabilityRelation == 1 ? true : false;
        //   return item.type == 0;
        // });
        // console.log(positiveCaseData, 'positiveCaseData==>')
        // let closeContactData = aa.filter((item) => {
        //   item.probabilityRelation =
        //     item.probabilityRelation == 1 ? true : false;
        //   return item.type == 1;
        // });
        // console.log(closeContactData, 'closeContactData==>')

        if (positiveCaseData.length > 0) {
          this.renderPositiveCase("positiveCaseRef", [...positiveCaseData]);
        }
        if (closeContactData.length > 0) {
          this.renderCloseContact("closeContactRef", [...closeContactData]);
        }
      }
    },
    //渲染其他信息
    renderOtherInfo() {
      const {
        medicalStaff,
        workUnit,
        clinicalSymptoms,
        dateOfFirstSymptom,
        firstSymptom,
        firstSymptomOther,
        vaccination,
        vaccinationType,
        vaccinationTypeOther,
        inoculationNum,
        finishVaccination,
        basicDiseases,
        basicDiseasesName,
        otherBasicDiseasesName,
      } = this.regitsterInfo;
      this.otherInfoData = {
        medicalStaff,
        workUnit,
        clinicalSymptoms,
        dateOfFirstSymptom,
        firstSymptom: firstSymptom ? firstSymptom.split(",") : [],
        firstSymptomOther,
        vaccination,
        vaccinationType: vaccinationType ? vaccinationType.split(",") : [],
        vaccinationTypeOther,
        inoculationNum,
        finishVaccination,
        basicDiseases,
        basicDiseasesName: basicDiseasesName
          ? basicDiseasesName.split(",")
          : [],
        otherBasicDiseasesName,
      };
    },
    //渲染阳性病例
    renderPositiveCase(key, data) {
      this.$nextTick(() => {
        this.$refs[key].positiveCaseInfo = [...data];
        let tabData = data.map((item) => {
          return {
            title: item.relationName,
            name: item.sort + "",
            data: { ...item },
          };
        });
        // console.log(tabData, 'tabData=>')
        this.$refs[key].editableTabs = tabData;
        this.$refs[key].editableTabsValue = tabData[tabData.length - 1].name;
        this.$refs[key].tabIndex = Number(tabData[tabData.length - 1].name);
      });
    },
    //渲染密接者
    renderCloseContact(key, data) {
      this.$nextTick(() => {
        this.$refs[key].closeContactInfo = data;
        let tabData = data.map((item) => {
          return {
            title: item.relationName,
            name: item.sort + "",
            data: item,
          };
        });
        this.$refs[key].editableTabs = tabData;
        this.$refs[key].editableTabsValue = tabData[tabData.length - 1].name;
        this.$refs[key].tabIndex = Number(tabData[tabData.length - 1].name);
      });
    },
    //选择任务来源
    handleTaskSource() {
      let node = this.$refs.taskSourceRef.getCheckedNodes()[0];
      if (node) {
        let taskSource = node.pathLabels.toString();
        this.regitsterInfo.taskSource = taskSource;
      }
    },
    //获取事件列表（事件名称列表）
    async getEventList(val) {
      const params = {};
      const { data } = await getEventListApi({ params });
      if (data.code == "200") {
        this.eventNameSelect = data.data;
        if (this.rowdata.eventName) {
          let a = { id: this.rowdata.eventId };
          this.ldcs(a);
        }
      }
    },
    //获取事件名称列表
    async remoteMethod(query) {
      if (query !== "") {
        // this.loading = true;
        await this.getEventList(query);
        // this.loading = false;
      }
    },
    //获取调查人员
    async getUserListByPeopleType() {
      const params = { peopleType: 4 };
      const { data } = await getUserListByPeopleTypeApi({ params });
      if (data.code == "200") {
        this.surveyUserNameSelect = data.data;
      }
    },
    //选择调查人员
    handleSurveyUserName(item) {
      this.regitsterInfo.surveyUserName = item.name;
      this.regitsterInfo.surveyUserId = item.id;
    },

    //选择所属地区
    handelSurveyObjectBelongArea(value) {
      // console.log(value,'111111111111111111111111111所属地区的value')
      let node = this.$refs.surveyObjectBelongAreaRef.getCheckedNodes()[0];
      if (node) {
        let surveyObjectBelongArea = node.pathLabels.toString();
        this.regitsterInfo.surveyObjectBelongArea = surveyObjectBelongArea;
        this.regitsterInfo.surveyObjectBelongAreaCode = value;
      }
    },

    //现住地
    handelCurrentAddress(value) {
      // console.log("选择完成时触发", "value");
      // console.log(value, 'value是11111111111111111111111111111区域编码');
      let node = this.$refs.currentAddressRef.getCheckedNodes()[0];
      if (node) {
        let currentAddress = node.pathLabels.toString();
        this.regitsterInfo.currentAddress = currentAddress;
        this.regitsterInfo.currentAddressCode = value;
        // console.log(
        //   this.regitsterInfo.currentAddressCode,
        //   "打印出codecodecode"
        // );
      }
    },

    // 获取组织架构列表
    async getOrgQueryList() {
      const { data } = await getMedicalOrgList();
      if (data.code == "200") {
        this.surveyObjectBelongOrgSelect = data.data;
      }
    },

    //选择主要人群分类（单选）
    handelCrowdType(item) {
      this.regitsterInfo.crowdTypeId = item.id;
      this.regitsterInfo.crowdType = item.code;
      this.regitsterInfo.crowdTypeName = item.ruleName;
    },

    // 选择次要人群分类（多选数组）
    selectchange(event) {
      let arr = [];
      for (let item1 in event) {
        for (let item2 in this.typepopSelect) {
          if (event[item1] == this.typepopSelect[item2].id) {
            arr.push(this.typepopSelect[item2]);
          }
        }
      }
      this.regitsterInfo.secondaryCrowdTypes = arr.map((item) => {
        return {
          crowdTypeId: item.id,
          crowdTypeName: item.ruleName,
          crowdType: item.code,
        };
      }); //更改数组里面对象的key
    },

    //选择所属社区
    handelSurveyObjectBelongOrg(value) {
      this.regitsterInfo.surveyObjectBelongOrg = value.orgName;
      this.regitsterInfo.surveyObjectBelongOrgCode = value.orgCode;
    },
    // 身份证计算
    idCodeCount(id) {
      if (id && id.length == 18) {
        let obj = getIdNumberInfo(id);
        this.regitsterInfo.sex = obj.sex == 0 ? "1" : "0";
        this.regitsterInfo.age = obj.age;
      } else {
        this.regitsterInfo.sex = "";
        this.regitsterInfo.age = "";
      }
    },

    //提交
    submitForm: _debounce(function () {
      const registerValidate = this.$refs.regitsterInfoForm.validate();
      let positiveCaseValidate = [];
      let closeContactValidate = [];
      if (!!this.regitsterInfo.crowdType) {
        positiveCaseValidate = this.$refs.positiveCaseRef.comm();
        if (this.regitsterInfo.crowdType == 7) {
          closeContactValidate = this.$refs.closeContactRef.comm();

          if (closeContactValidate.length == 0) {
            this.$message({
              showClose: true,
              message: "关联密切接触者（一密）必须添加一个",
              type: "error",
            });
          }
        }
      }
      Promise.all([
        registerValidate,
        ...positiveCaseValidate,
        ...closeContactValidate,
      ])
        .then((res) => {
          // if (!this.verifyFormInfo()) return;
          this.componentSubmit();
          // 新增要查重，修改不查重
          if (this.regitsterInfo.glrz == "" || this.type != "edit") {
            this.checkIsReg();
          } else if (this.type == "edit") {
            this.updateSurveyInfo();
          }
        })
        .catch((res) => {
          // console.log(res);
          // console.log(666);
          this.$message({
            message: "必填项未填写或填写格式不符合",
            type: "warning",
            offset: 60,
          });
        });
    }, 300),
    //重置
    resetForm() {
      if (this.type == "add") {
        this.$refs.regitsterInfoForm.resetFields();
        this.$refs.otherInfoRef.resetf();
        this.regitsterInfo.crowdType = "";
        this.regitsterInfo.otherCrowdTypeName = "";
        this.regitsterInfo.taskRemark = "";
        this.regitsterInfo.surveyResultDes = "";
        this.regitsterInfo.typepopSelect = [];
        this.regitsterInfo.glrz = "";
        return;
      }
      if (this.type == "edit") {
        this.getSurveyInfo(this.id);
      }
    },

    //提交业务逻辑校验
    verifyFormInfo() {
      const { surveyResult, surveyResultDes, crowdType } = this.regitsterInfo;
      //说明项
      // if (surveyResult == 0 || surveyResult == 2) {
      //   if (!surveyResultDes) {
      //     this.$message.warning("流调结果为排除或居家隔离时需要填写说明");

      //     return false;
      //   }
      // }
      //居家隔离
      // if (surveyResult == 2) {
      //   if (crowdType != 7) {
      //     this.$message.warning("流调结果为居家隔离时只能是次密人员！");

      //     return false;
      //   }
      // }

      return true;
    },
    //调用组件事件
    componentSubmit() {
      let { crowdType } = this.regitsterInfo;
      let survey, surveyRelationList;
      //  crowdType 7次密接医学观察者  剩余其他
      if (crowdType == 7) {
        this.$refs.closeContactRef.commData();
        this.$refs.positiveCaseRef.commData();
        this.$refs.otherInfoRef.commData();

        surveyRelationList = [
          ...this.$refs.positiveCaseRef.positiveCaseInfo,
          ...this.$refs.closeContactRef.closeContactInfo,
        ];
      } else {
        this.$refs.positiveCaseRef.commData();
        this.$refs.otherInfoRef.commData();
        surveyRelationList = this.$refs.positiveCaseRef.positiveCaseInfo;
      }
      let nubnono = 0;
      for (let i in surveyRelationList) {
        let a = surveyRelationList[i].probabilityRelation;
        if (a == true || a == 1) {
          nubnono = nubnono + 1;
        }
      }

      // console.log(nubnono, "nubnono打印nooooo");

      if (
        (nubnono >= 2 && this.regitsterInfo.crowdType == 7) ||
        (this.regitsterInfo.crowdType == 4 && nubnono >= 2)
      ) {
        this.$message.error("请只勾选一位最大关联者");
        return;
      } else if (
        (nubnono == 0 && this.regitsterInfo.crowdType == 7) ||
        (this.regitsterInfo.crowdType == 4 && nubnono == 0)
      ) {
        this.$message.error("请勾选一位最大关联者");
        return;
      }

      let idd = "";
      if (this.type == "addlddj") {
        idd = "";
      } else {
        idd = this.regitsterInfo.id;
      }
      //  找到选择场所id
      let placeId;
      for (let i in this.eventcsSelect) {
        if (
          this.eventcsSelect[i].placeName == this.regitsterInfo.surveyAddress
        ) {
          placeId = this.eventcsSelect[i].id;
        }
      }
      //提交时数组数据处理
      let regidd;

      if (this.regitsterInfo.glrz) {
        if (this.regitsterInfo.glrz.indexOf(" ") == -1) {
          regidd = this.regitsterInfo.glrz;
          // 没有空格
        } else {
          let idarr = this.regitsterInfo.glrz.split(" ");
          regidd = idarr[1];
        }
      }

      survey = {
        ...this.regitsterInfo,
        ...this.$refs.otherInfoRef.otherInfo,
        preTaskUserId: this.rowdata.id,
        preTaskId: this.rowdata.preTaskId,
        preTaskNo: this.rowdata.preTaskNo,
        id: idd,
        placeId: placeId,
        regId: this.regitsterInfo.regId,
        // regId: this.cgldregId
      };

      if (survey.crowdType != 11) {
        survey.otherCrowdTypeName = "";
      }
      // if (survey.surveyResult == 3) {
      //   survey.surveyResultDes = "";
      // }
      survey.surveyDate = this.$moment(survey.surveyDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      survey.taskSourceCode =
        survey.taskSourceCode.length > 0
          ? survey.taskSourceCode.toString()
          : "";
      // console.log(survey.taskSourceCode, "提交时任务来 code ，应该是字符串");
      survey.currentAddressCode =
        survey.currentAddressCode.length > 0
          ? survey.currentAddressCode.toString()
          : "";
      survey.surveyObjectBelongAreaCode =
        survey.surveyObjectBelongAreaCode.length > 0
          ? survey.surveyObjectBelongAreaCode.toString()
          : "";
      survey.vaccinationType =
        survey.vaccinationType.length > 0
          ? survey.vaccinationType.toString()
          : "";
      survey.basicDiseasesName =
        survey.basicDiseasesName.length > 0
          ? survey.basicDiseasesName.toString()
          : "";
      survey.firstSymptom =
        survey.firstSymptom.length > 0 ? survey.firstSymptom.toString() : "";
      survey.dateOfFirstSymptom = !!survey.dateOfFirstSymptom
        ? this.$moment(survey.dateOfFirstSymptom).format("YYYY-MM-DD HH:mm:ss")
        : "";
      if (surveyRelationList.length > 0) {
        surveyRelationList.forEach((item) => {
          item.dateOfOnset = !!item.dateOfOnset
            ? this.$moment(item.dateOfOnset).format("YYYY-MM-DD HH:mm:ss")
            : "";
          item.firstContactTime = !!item.firstContactTime
            ? this.$moment(item.firstContactTime).format("YYYY-MM-DD HH:mm:ss")
            : "";
          item.lastContactTime = !!item.lastContactTime
            ? this.$moment(item.lastContactTime).format("YYYY-MM-DD HH:mm:ss")
            : "";
          item.relationAddressCode =
            !!item.relationAddressCode && item.relationAddressCode.length > 0
              ? item.relationAddressCode.toString()
              : "";
          item.probabilityRelation = item.probabilityRelation == true ? 1 : 0;

          let axx = Object.keys(item).indexOf("sort");
          if (axx == -1) {
            item.sort = 1;
          }
        });
      }
      let files = this.tempFileList;
      this.formInfo = { survey, surveyRelationList, files };
    },
    //校验是否已经流调登记
    async checkIsReg() {
      const params = this.formInfo;
      const { data } = await checkIsRegApi(params);
      // console.log(data,'13210000000000000000');

      if (data.data == null) {
        //没有 重复人员
        this.type == "edit" ? this.updateSurveyInfo() : this.saveSurveyInfo();
      } else if (this.dialogVisible == false) {
        //打开警告框
        this.dialogVisible = true;
        this.dialogMessage = data.data;
        this.isReg = data.data;
      } else if (this.dialogVisible == true) {
        this.type == "edit" ? this.updateSurveyInfo() : this.saveSurveyInfo();
        this.dialogVisible == false;
      }
    },

    //新增调查登记(提交)
    async saveSurveyInfo() {
      const params = this.formInfo;
      if(this.regitsterInfo.crowdType==4){
          const allLastTimeFlag = this.$refs.positiveCaseRef.positiveCaseInfo.every(o => !!o.lastContactTime);
          if (!allLastTimeFlag) {
            this.$message({
              message: '请选择阳性关联者最后接触时间',
              type: 'error'
            })
            return;
          }
        }

      let a = JSON.parse(JSON.stringify(params.surveyRelationList));
      
      if(Array.isArray(a)){
        for (let i of a) {
        // console.log("updateSurveyInfo ->   i", i);
        // console.log(
        //   "updateSurveyInfo ->   i.probabilityRelation",
        //   i.probabilityRelation
        // );
        if (i.probabilityRelation == true) {
          i.probabilityRelation = "1";
        }
      }
      params.surveyRelationList = a;
      }

      

      const { data } = await saveSurveyInfoApi(params);
      if (data.code == "200") {
        this.$message.success("提交成功！");
        this.goBack();
      }
    },
    //编辑调查登记
    async updateSurveyInfo() {

      const params = this.formInfo;
      if(this.regitsterInfo.crowdType==4){
          const allLastTimeFlag = this.$refs.positiveCaseRef.positiveCaseInfo.every(o => !!o.lastContactTime);
          if (!allLastTimeFlag) {
            this.$message({
              message: '请选择阳性关联者最后接触时间',
              type: 'error'
            })
            return;
          }
        }


      let a = JSON.parse(JSON.stringify(params.surveyRelationList));

      for (let i of a) {
        // console.log(
        //   "updateSurveyInfo ->   i.probabilityRelation",
        //   i.probabilityRelation
        // );
        if (i.probabilityRelation == true) {
          i.probabilityRelation = "1";
        }
      }
      params.surveyRelationList = a;
      const { data } = await updateSurveyInfoApi(params);
      if (data.code == "200") {
        this.$message.success("修改成功！");
        this.goBack();
      }
    },
    //获取百度经纬度
    getBmap() {
      if (this.regitsterInfo.currentAddress) {
        this.$jsonp("https://api.map.baidu.com/geocoding/v3", {
          address:
            this.regitsterInfo.currentAddress +
            this.regitsterInfo.currentAddressDetail,
          output: "json",
          ak: "K47zbc0avMITSmQCoPu3oU9Eg9HLsII4",
        })
          .then((json) => {
            this.regitsterInfo.currentAddressBdTuid =
              json.result.location.lng + "," + json.result.location.lat;
            const currentAddressGdTuid = this.bMapTransqqMap(
              json.result.location.lng,
              json.result.location.lat
            );
            this.regitsterInfo.currentAddressGdTuid =
              currentAddressGdTuid.lng + "," + currentAddressGdTuid.lat;
          })
          .catch((err) => {});
      }
    },
    //百度转高德
    bMapTransqqMap(lng, lat) {
      const X_PI = (Math.PI * 3000.0) / 180.0;
      let x = lng - 0.0065;
      let y = lat - 0.006;
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
      let amap_lng = z * Math.cos(theta);
      let amap_lat = z * Math.sin(theta);
      return {
        lng: amap_lng,
        lat: amap_lat,
      };
    },

    //  （现在是次要人群分类 多选）
    async getCrowdTypeList() {
      const { data } = await getCrowdTypeList();
      if (data.code == "200") {
        this.typepopSelect = data.data;
      }
    },
    // 查询已入住人员未关联流调列表
    async getRegLiveUnSurveyUserList() {
      let params = {};
      const { data } = await getRegLiveUnSurveyUserList({ params });
      if (data.code == "200") {
        for (let i in data.data) {
          data.data[
            i
          ].zdm = `${data.data[i].name} ${data.data[i].cardNo} ${data.data[i].isolationPointName}`;
        }
        this.typepopSelect11 = data.data;
      }
    },
    // 编辑的时候已入住人员未关联流调列表
    async bjgetRegLiveUnSurveyUserList() {
      let params = {
        regId: this.regitsterInfo.regId,
      };
      const { data } = await getRegLiveUnSurveyUserList({ params });
      if (data.code == "200") {
        for (let i in data.data) {
          data.data[
            i
          ].zdm = `${data.data[i].name} ${data.data[i].cardNo} ${data.data[i].isolationPointName}`;
          if (
            this.regitsterInfo.regId == data.data[i].regId &&
            this.regitsterInfo.regId != undefined
          ) {
            this.regitsterInfo.glrz = `${data.data[i].name} ${data.data[i].cardNo} ${data.data[i].isolationPointName}`;
            // console.log(
            //   "bjgetRegLiveUnSurveyUserList ->  this.glrz",
            //   this.regitsterInfo.glrz
            // );
          } else {
            // this.regitsterInfo.glrz = "";
          }
        }
        if (this.type == "edit") {
          // this.regitsterInfo.glrz =;
        }
        this.typepopSelect11 = data.data;
        this.$forceUpdate();
      }
    },
    // 获取调查场所
    async ldcs(v) {
      if (this.regitsterInfo.eventId) {
          // this.regitsterInfo.eventName = v.eventName;

        if(this.type !='addlddj'){
          this.regitsterInfo.eventName = v.eventName;
        }
      }
      let params = { eventId: v.id };
      const { data } = await getPlaceList({ params });

      if (data.code == "200") {
        this.eventcsSelect = data.data;
        if (this.rowdata.placeInvolved) {
          this.regitsterInfo.surveyAddress = this.rowdata.placeInvolved;
        }
      }
    },
    // 回显
    async echo(v) {
      let params = {
        regId: v.regId,
      };
      const { data } = await getRegById({ params });
      // console.log(data,'新增流调登记时，选择关联已入住人员的信息')
      if (data.code == "200") {
        let id = this.regitsterInfo.id;
        // console.log(this.regitsterInfo,'this.regitsterInfo========>输出regitsterInfo')
        // console.log(this.rowdata,'this.rowdata========> 输出rowdata ')
        // console.log(data.data,'data.data======>')

        let a = { ...this.regitsterInfo, ...this.rowdata, ...data.data };
        // console.log(a,'这里输出a，是几个对象解构后组成的新对象')

        // 为了解决编辑修改人员id报错
        if (this.type == "edit") {
          this.regitsterInfo = a; //流调人员回显
          this.regitsterInfo.id = id;
          this.regitsterInfo.regId = data.data.id;
        } else {
          this.regitsterInfo = a; //流调人员回显
          this.regitsterInfo.regId = data.data.id;
        }
        // this.regitsterInfo = a; //流调人员回显

        if (this.regitsterInfo.age == "") {
          this.idCodeCount(this.regitsterInfo.cardNo);
        }
      }
    },
    //上传文件大小限制
    beforeUpload(file) {
      let size = file.size / 1024 / 1024 < 20;
      if (!size) {
        this.$message.warning("上传文件大小不能超过20M");
        return false;
      }
    },
    //文件上传成功
    handelSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.tempFileList.push({
          name: file.response.data.originalName,
          url: file.response.data.imgUrl,
        });
      }
    },
    //文件上传失败
    handelError(error) {
      if (res.code == 200) {
        this.$message({
          message: "上传失败",
          type: "error",
        });
      }
    },
    //文件删除提示询问（做权限）
    beforeRemove(file) {
      // console.log(file, 'file-before-remove')
      if (file.disableRemove) {
        this.$confirm(
        "该文件不可以删除",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      return !file.disableRemove;
      } else {
        return this.$confirm("确定要删除文件吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
    },
    //文件删除
    handleRemove(file) {
      if (file.status != "success") return;
      const deleteIndex = this.tempFileList.findIndex((item) => {
        if (file.response) {
          return item.url == file.response.data.imgUrl;
        } else {
          return item.url == file.url;
        }
      });
      this.tempFileList.splice(deleteIndex, 1);
    },
    //文件下载
    handlePreview(file) {
      let loadingInstance = Loading.service();
      Loading.service();
      if (file.status != "success") return;
      let fileUrl;
      if (file.response) {
        fileUrl = file.response.data.imgUrl;
      } else {
        fileUrl = file.url;
      }
      //获取文件后缀
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (["png", "jpg"].includes(suffix)) {
        let alink = document.createElement("a");
        alink.href = fileUrl;
        alink.target = "_blank";
        alink.download = file.name; //fileName保存提示中用作预先填写的文件名
        alink.click();
      } else if (["docx", "doc", "pdf", "xls", "xlsx"].includes(suffix)) {
        importUrlFile(fileUrl, file.name);
      } else {
        return;
      }
      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },
    // 次密选择可能性最大，阳性取消 分别清空对方的
    father() {
      this.$refs.positiveCaseRef && this.$refs.positiveCaseRef.onenmbfyc("1234");
    },
    // 阳性选择可能性最大，次密取消 分别清空对方的
    father102() {
      this.$refs.closeContactRef && this.$refs.closeContactRef.onenmbfyc("123");
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top_left {
      font-size: 18px;
      font-weight: bolder;
      color: #666666;
    }

    .top_right {
      font-size: 12px;
      color: #9a9a9a;

      .xinxin {
        color: #ff5050;
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-item1 {
  width: 100%;
  height: 100%;
}

.special {
  flex: 1;
}

// .special1 {}

.formitem {
  width: 50%;
}

.select-width {
  width: 80%;
}

.select-width2 {
  width: 400px;
  margin-right: 80px;
}

.btns {
  display: flex;
  justify-content: center;
}

.warning {
  margin-bottom: 20px;
  padding-left: 20px;
}

.ph {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 40px;
}

.classify {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.classify .select-width {
  width: 240px;
}

.classify .other-input {
  width: 200px;
}

.other-input {
  margin-left: 10px;
}

.el-icon-warning-outline {
  color: #ff9900;
  font-size: 26px;
  font-weight: bold;
  margin-right: 10px;
}
.notification {
  display: flex;
  flex-wrap: nowrap;
  width: 80%;
  margin: 20px auto;
  justify-content: space-around;
  .NameNotification {
    width: 12%;
  }
  .CardIdNotification {
    width: 35%;
  }

  .OrgPhoneNotification {
    width: 35%;
  }

  .TypeNotification {
    width: 20%;
  }
}
</style>