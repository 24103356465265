<template>
  <div class="other-info">
    <div class="title">
      <span class="blue"></span>
      <p style="font-size:16px">调查对象其他信息</p>
    </div>
    <el-form :model="otherInfo" ref="otherInfoForm" label-width="180px">
      <div class="content-item">
        <el-form-item class="special" label="是否是医护人员:" prop="medicalStaff">
          <el-radio-group v-model="otherInfo.medicalStaff" class="select-width">
            <el-radio v-for="item in threeSelect" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="otherInfo.medicalStaff == 1">
          <el-form-item class="special" label="工作单位：" prop="workUnit">
            <el-input v-model="otherInfo.workUnit" placeholder="请输入" class="select-width" />
          </el-form-item>
        </template>
      </div>
      <div class="content-item">
        <el-form-item class="special" label="是否出现临床症状：" prop="clinicalSymptoms">
          <el-radio-group v-model="otherInfo.clinicalSymptoms" class="select-width">
            <el-radio v-for="item in threeSelect" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <template v-if="otherInfo.clinicalSymptoms == 1">
        <div class="content-item">
          <el-form-item class="formitem" label="首次出现症状日期：" prop="dateOfFirstSymptom">
            <el-date-picker class="select-width" v-model="otherInfo.dateOfFirstSymptom" type="date" placeholder="请选择日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="content-item">
          <el-form-item class="special" label="首次症状的表现：" prop="firstSymptom">
            <el-checkbox-group v-model="otherInfo.firstSymptom">
              <el-checkbox label="发热"></el-checkbox>
              <el-checkbox label="咽痛"></el-checkbox>
              <el-checkbox label="气促"></el-checkbox>
              <el-checkbox label="腹泻"></el-checkbox>
              <el-checkbox label="寒战"></el-checkbox>
              <el-checkbox label="头痛"></el-checkbox>
              <el-checkbox label="呼吸困难"></el-checkbox>
              <el-checkbox label="腹痛"></el-checkbox>
              <el-checkbox label="咳嗽"></el-checkbox>
              <el-checkbox label="乏力"></el-checkbox>
              <el-checkbox label="胸闷"></el-checkbox>
              <el-checkbox label="鼻塞"></el-checkbox>
              <el-checkbox label="肌肉酸痛"></el-checkbox>
              <el-checkbox label="恶心"></el-checkbox>
              <el-checkbox label="流涕"></el-checkbox>
              <el-checkbox label="关节酸痛"></el-checkbox>
              <el-checkbox label="呕吐"></el-checkbox>
              <el-checkbox label="不详"></el-checkbox>
              <el-checkbox label="其他"></el-checkbox>
              <template v-if="otherInfo.firstSymptom.includes('其他')">
                <el-input style="margin-left:20px;width:200px" v-model="otherInfo.firstSymptomOther"
                  placeholder="请输入" />
              </template>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </template>
      <div class="content-item">
        <el-form-item class="special" label="是否接种了新冠病毒疫苗：" prop="vaccination">
          <el-radio-group v-model="otherInfo.vaccination" class="select-width">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-show="otherInfo.vaccination == 1">
        <div class="content-item">
          <el-form-item class="special" label="接种了哪种新冠病毒疫苗：" prop="vaccinationType">
            <el-checkbox-group v-model="otherInfo.vaccinationType">
              <el-checkbox label="北京生物">北京生物</el-checkbox>
              <el-checkbox label="科兴中维">科兴中维</el-checkbox>
              <el-checkbox label="智飞生物">智飞生物</el-checkbox>
              <el-checkbox label="其他">其他
                <template v-if="otherInfo.vaccinationType.includes('其他')">
                  <el-input style="margin-left:10px;width:200px" v-model="otherInfo.vaccinationTypeOther"
                    placeholder="请输入" />
                </template>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div class="content-item">
          <el-form-item class="special" label="接种了几剂新冠病毒疫苗：" prop="inoculationNum">
            <el-radio-group v-model="otherInfo.inoculationNum" class="select-width">
              <el-radio label="1">1剂</el-radio>
              <el-radio label="2">2剂</el-radio>
              <el-radio label="3">3剂</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="content-item">
          <el-form-item class="special" label="是否完成了新冠病毒疫苗全程接种：" prop="finishVaccination">
            <el-radio-group v-model="otherInfo.finishVaccination" class="select-width">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="content-item">
        <el-form-item class="special" label="是否有基础性疾病：" prop="basicDiseases">
          <el-radio-group v-model="otherInfo.basicDiseases" class="select-width">
            <el-radio v-for="item in threeSelect" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-show="otherInfo.basicDiseases == 1">
        <div class="content-item">
          <el-form-item class="special" label="基础性疾病名称：" prop="basicDiseasesName">
            <el-checkbox-group v-model="otherInfo.basicDiseasesName">
              <el-checkbox label="高血压">高血压</el-checkbox>
              <el-checkbox label="肺气肿">肺气肿</el-checkbox>
              <el-checkbox label="免疫缺陷">免疫缺陷</el-checkbox>
              <el-checkbox label="糖尿病">糖尿病</el-checkbox>
              <el-checkbox label="慢性支气管炎">慢性支气管炎</el-checkbox>
              <el-checkbox label="艾滋病">艾滋病</el-checkbox>
              <el-checkbox label="脑血管病">脑血管病</el-checkbox>
              <el-checkbox label="肺癌">肺癌</el-checkbox>
              <el-checkbox label="肺结核">肺结核</el-checkbox>
              <el-checkbox label="冠心病">冠心病</el-checkbox>
              <el-checkbox label="慢性肝病">慢性肝病</el-checkbox>
              <el-checkbox label="哮喘">哮喘</el-checkbox>
              <el-checkbox label="哮喘">哮喘</el-checkbox>
              <el-checkbox label="慢性肾病">慢性肾病</el-checkbox>
              <el-checkbox label="其他">其他
                <template v-if="otherInfo.basicDiseasesName.includes('其他')">
                  <el-input style="margin-left:10px;width:200px" v-model="otherInfo.otherBasicDiseasesName"
                    placeholder="请输入" />
                </template>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { THREE_SELECT } from "@/common/constants";
export default {
  data() {
    return {
      otherInfo: this.formData,
    };
  },
  props: {
    formData: {
      type: Object,
      default: function () {
        return {
          medicalStaff: "",
          workUnit: "",
          clinicalSymptoms: "",
          dateOfFirstSymptom: "",
          firstSymptom: [],
          firstSymptomOther: "",
          vaccination: "",
          vaccinationType: [],
          vaccinationTypeOther: "",
          inoculationNum: "",
          finishVaccination: "",
          basicDiseases: '',
          basicDiseasesName: [],
          otherBasicDiseasesName: '',
        };
      },
    },
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.otherInfo = newVal;
      },
      deep: true,
    },
  },
  created() {
    this.threeSelect = THREE_SELECT;
  },
  methods: {
    // 提交
    commData() {
      if (this.otherInfo.basicDiseases != 1) {
        this.otherInfo.basicDiseasesName = [];
        this.otherInfo.otherBasicDiseasesName = '';
      }
      if (this.otherInfo.vaccination != 1) {
        this.otherInfo.vaccinationType = [];
        this.otherInfo.vaccinationTypeOther = '';
      }
      if (this.otherInfo.clinicalSymptoms != 1) {
        this.otherInfo.dateOfFirstSymptom = "";
        this.otherInfo.firstSymptom = [];
        this.otherInfo.firstSymptomOther = "";
      }
      if (this.otherInfo.medicalStaff != 1) {
        this.otherInfo.workUnit = '';
      }

    },
    // 重置
    resetf() {
      this.$refs.otherInfoForm.resetFields();
      this.otherInfo.firstSymptomOther = "";
      this.otherInfo.vaccinationTypeOther = "";
      this.otherInfo.otherBasicDiseasesName = "";
    },
  },
};
</script>
<style scoped lang="scss">
.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formitem {
  width: 50%;
}

.special {
  flex: 1;
}

.select-width {
  width: 80%;
}
</style>