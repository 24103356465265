<template>
  <div>
    <div class="title">
      <span class="blue"></span> 
      <p>关联密切接触者（一密）</p>
      <el-button type="primary" class="add-btn" size="small" @click="addCloseCase">添加</el-button>
    </div>
    <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab">
      <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
        <!-- {{item.data}} -->
        <close-contact-form :ref="`closeContactFormRef${item.data.sort}`" :sort="item.data.sort" :formData="item.data"
          :editableTabs="editableTabs" :gsoncrowdType="gsoncrowdType" @onenmb="onenmb" ></close-contact-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import CloseContactForm from "./CloseContactForm.vue";
export default {
  props: ["crowdType","addtype"],
  name: "RelateCloseContact",
  data() {
    return {
      closeContactInfo: [],
      tabIndex: 0,
      editableTabs: [],
      editableTabsValue: "0",
      gsoncrowdType:""
    };
  },
  components: { CloseContactForm },
 
  methods: {
    //新增密接
    addCloseCase() {

      console.log("🚀 ~一密状态this.crowdType", this.crowdType);
      
      console.log("🚀 ~ 一密 这个this.addtype", this.addtype);

      let newTabName = ++this.tabIndex + "";
      let data = {
        type: 1,
        sort: this.tabIndex,
        relationName: "",
        relationCardType: "1",
        relationCardNo: "",
        relationAddress: "",
        relationAddressCode: "",
        probabilityRelation: false,
        dateOfOnset: "",
        receivingOrg: "",
        relationType: "",
        otherRelation: "",
        firstContactTime: "",
        lastContactTime: "",
        contactFrequencyType: "",
        contactAddress: "",
        contactType: "",
        vehicle: "",
        singleExposureTime: "",
        gsoncrowdType: this.gsoncrowdType,
        crowdType: this.crowdType,
        addtype:this.addtype
      };

      if(this.addtype == 'add'&&this.crowdType == '7' && !this.editableTabs.length){
          data.probabilityRelation = true;
        }

        if(this.addtype =='edit'&&this.crowdType == '7'&& !this.editableTabs.length){
        data.probabilityRelation = true;
      }

      // console.log("添加", data.sort);
      this.closeContactInfo.push(data);
      this.editableTabs.push({
        title: `关联${this.editableTabs.length+1}`,
        name: newTabName,
        data: data,
      });
      this.editableTabsValue = newTabName;
      console.log("123", this.editableTabs);
    },
    //删除关联者
    removeTab(targetName, action) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      //删除tab
      let tabIndex = tabs.findIndex((item) => {
        return item.name == targetName;
      });
      this.editableTabs.splice(tabIndex, 1);
      //删除positiveCaseInfo
      let closeContactIndex = this.closeContactInfo.findIndex((item) => {
        return item.sort == targetName;
      });
      this.closeContactInfo.splice(closeContactIndex, 1);
    },
    //渲染数据
    renderData() {
      this.editableTabs.forEach((item) => {
        this.$ref[`closeContactFormRef${item.data.sort}`].positiveCaseForm =
          item.data;
      });
    },
    // 提交校验
    comm() {
      return this.editableTabs.map((item) => {
        return this.$refs[`closeContactFormRef${item.data.sort}`][0].commit();
      });
    },
    //提交数据
    commData() {
      let wfnlbugg = [];
      this.editableTabs.forEach((item) => {
        let data =
          this.$refs[`closeContactFormRef${item.data.sort}`][0]
            .closeContactForm;

        const index = this.closeContactInfo.findIndex((item) => {
          return item.sort == data.sort;
        });

        let sorrtttt = JSON.parse(JSON.stringify(item.data.sort));

        data.sort = sorrtttt;

        console.log("🚀 ~  index ", index);
        console.log("🚀 ~  data ", data);
        console.log("🚀 ~  item ", item);

        sessionStorage.setItem(`wca${sorrtttt}`, JSON.stringify(data));

        let aaa = JSON.parse(sessionStorage.getItem(`wca${sorrtttt}`)); //取

        wfnlbugg.push(aaa);
        // this.closeContactInfo.splice(index, 1, aaa);
        // sessionStorage.removeItem("wca"); //删
        console.log("🚀 ~提交一次this.wfnlbugg", wfnlbugg);
      });

      console.log("🚀 ~提交最后this.wfnlbugg", wfnlbugg);

      for (let i in this.closeContactInfo) {
        if (this.closeContactInfo[i].sort == wfnlbugg[i].sort) {
          this.closeContactInfo[i] = wfnlbugg[i];
        }
      }
      console.log("🚀 ~提交最后this.closeContactInfo", this.closeContactInfo);
    },
    // 重置
    resetf() {
      this.editableTabs.forEach((item) => {
        this.$refs[`closeContactFormRef${item.data.sort}`][0].reset();
      });
      this.editableTabs = [];
    },
    // 可能性最大的关联者只能勾选一个
    onenmb() {
      for (let i of this.editableTabs) {
        this.$refs[
          `closeContactFormRef${i.data.sort}`
        ][0].closeContactForm.probabilityRelation = false;
      }

      this.$emit("father", "123");
    },
    // 防溢出
    onenmbfyc() {
      for (let i of this.editableTabs) {
        this.$refs[
          `closeContactFormRef${i.data.sort}`
        ][0].closeContactForm.probabilityRelation = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
} 

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.add-btn {
  margin-left: 20px;
}

.select-width {
  width: 80%;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formitem {
  width: 50%;
}

.special {
  flex: 1;
}

.relation-width {
  display: inline;
  width: 50%;
}
</style>